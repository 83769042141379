import React from 'react'

import Block404 from '../components/404'
import Layout from '../components/Layout'

function Page404({ location }) {
  return (
    <Layout location={location}>
      <div className="redirect">
        <Block404 />
      </div>
    </Layout>
  )
}

export default Page404
