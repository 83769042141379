import React from 'react'
import { PageLink } from '../elements/PageLink'

function Block404() {
  return (
    <section id="heroSection" className="simple-hero section page-404">
      <div className="container-fluid-small">
        <div className="row">
          <div className="col-12">
            <h6 className="sub-title">Nothing Found</h6>
            <h1 className="big-title">Ooops... Seems like you got lost.</h1>
            <PageLink to="/">Go Home</PageLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Block404
